<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <!-- Logo and Title -->
      <a class="navbar-brand" href="#">YourApp</a>
  
      <!-- Toggle Button for Small Screens -->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
  
      <!-- Navigation Links -->
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <a class="nav-link" routerLink="/home" routerLinkActive="active">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/membership" routerLinkActive="active">Membership</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/events" routerLinkActive="active">Events</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/initiatives" routerLinkActive="active">Initiatives</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/fundraising" routerLinkActive="active">Fundraising</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/sponsorship" routerLinkActive="active">Sponsorship</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/volunteer" routerLinkActive="active">Volunteer</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/about" routerLinkActive="active">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/gallery" routerLinkActive="active">Gallery</a>
          </li>
          <li class="nav-item" *ngIf="hasDashboardAccess">
            <a class="nav-link" routerLink="/dashboard" routerLinkActive="active">Dashboard</a>
          </li>
  
          <!-- Dropdown for About -->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              About
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" routerLink="/pto-team" routerLinkActive="active">PTO Team</a></li>
              <li><a class="dropdown-item" routerLink="/bylaws" routerLinkActive="active">ByLaws</a></li>
              <li><a class="dropdown-item" routerLink="/meeting-minutes" routerLinkActive="active">Meeting & Minutes</a></li>
              <li><a class="dropdown-item" routerLink="/finances" routerLinkActive="active">Finances</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  