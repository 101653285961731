import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { MainLayoutComponent } from './app-main-layout/app-main-layout.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { TodoComponent } from './todo/todo.component';
import { ManageEventsComponent } from './event-list/event-list.component';
import { EventListReactiveComponent } from './event-list-reactive/event-list-reactive.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: '',
    component: MainLayoutComponent,
    //canActivate: [AuthGuard], // Protect this layout with the AuthGuard
    children: [
      { path: '',redirectTo: 'home',pathMatch: 'full'},      
      { path: 'home', component: HomeComponent },
      { path: 'counter', component: CounterComponent },
      { path: 'fetch-data', component: FetchDataComponent },
      { path: 'todo', component: TodoComponent },
      { path: 'eventlist', component: ManageEventsComponent },
      { path: 'eventlistreactive', component: EventListReactiveComponent },
      { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },

    ],
  },
  // { path: '**', redirectTo: '/login' },
  // Add other routes here
];


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ]
})
export class AppRoutingModule { }
