import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';

interface AppConfig {
  schoolShortName?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private config: AppConfig | null = null;

  constructor(private http: HttpClient) {}

  // Method to load the configuration
  loadConfig(): Observable<AppConfig> {
    return this.http.get<AppConfig>('assets/config.json').pipe(
      catchError((error) => {
        console.error('Error loading configuration', error);
        throw error;
      })
    );
  }

  // Method to get a specific config value
  getConfig(): AppConfig {
    if (!this.config) {
      throw new Error('Configuration not loaded');
    }
    return this.config;
  }

  getShortNameFromConfig() {
    if (!this.config) {
      throw new Error('Configuration not loaded');
    }
    return this.config.schoolShortName;
  }

  // Set the config manually if needed
  setConfig(config: AppConfig): void {
    this.config = config;
  }
}
