import { Injectable } from '@angular/core';
import { jwtDecode} from 'jwt-decode';

interface User {
  username: string;
  userId: string;
  // roles: string[];
  role: string;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private tokenKey: string = 'jwt'; // The key used to store the token in localStorage

  constructor() {}

  // Get the JWT token from localStorage
  getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  // Decode the JWT token and return the user details
  getUserDetails(): User | null {
    const token = this.getToken();
    if (token) {
      try {
        // Decode the token and extract the claims
        const decodedToken: any = jwtDecode(token);

        console.log(decodedToken);

        // Extract claims from the decoded token
        // const username = this.getClaimValue(decodedToken,'name') || '';  // Username claim
        // const userId =  this.getClaimValue(decodedToken,'nameidentifier')  || '';  // UserId claim
        // // const roles = decodedToken.role  || [];  // Roles claim (this can be an array of roles)
        // const role = this.getClaimValue(decodedToken,'role') || '';

        const username = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'] || '';  // Username claim
        const userId =  decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'] || '';  // UserId claim
        // const roles = decodedToken.role  || [];  // Roles claim (this can be an array of roles)
        const role = decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] || '';

        // Return the user details in the expected format
        return {
          username,
          userId,
          // roles: Array.isArray(roles) ? roles : [roles], // Ensure roles is always an array
          role: role, 
        };
      } catch (error) {
        console.error('Error decoding the token:', error);
        return null;
      }
    }
    return null;
  }

  getClaimValue(decodedToken, claimName){
    const urn = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/';
    return decodedToken[urn+claimName];
  }

  // Check if the user is logged in (i.e., token exists and is not expired)
  isLoggedIn(): boolean {
    const token = this.getToken();
    if (!token) {
      return false;
    }

    // Optionally, check if the token is expired
    const decodedToken: any = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    if (decodedToken.exp && decodedToken.exp < currentTime) {
      return false; // Token is expired
    }
    return true;
  }

  // Optionally, clear the token and user data (e.g., logout)
  logout(): void {
    localStorage.removeItem(this.tokenKey);
  }
}
