import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user-service.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
  
  hasDashboardAccess:boolean=false;
  isExpanded = false;
  
  navbarOpen = false;
  dropdowns = {
    membership: false,
    events: false,
    initiatives: false,
    fundraising: false,
    about: false,
    admin: false,
  };

  
  constructor(private userService:UserService) {
    
    
  }

  ngOnInit(): void {

    const user = this.userService.getUserDetails();
    if(user.role.toLowerCase() === "admin" || user.role.toLowerCase() ==="superadmin")
      this.hasDashboardAccess = true;
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  toggleDropdown(menu: string) {
    this.dropdowns[menu] = !this.dropdowns[menu];
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
