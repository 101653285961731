<div class="divEventMainContainer">
    <h3 class="header">PTO Events & Fundraisers</h3>
    <p>All dates are tentative and subject to change</p>

    <div class="eventContainer">
        <div class="events" *ngFor="let season of events.publicEventsBySeasons">
            <h4>{{ season.seasonYear }}</h4>
            <div *ngFor="let event of season.publicEvents">
                <em style="color:#000000">{{ event.eventName }} </em>
                <strong style="color:#000000"><em>({{ event.eventStartDate | date: 'MM/dd EEEE' }})</em></strong>
            </div>
        </div>
    </div>


    <!-- <ul>
        <li *ngFor="let event of events.publicEvents">
            {{ event.eventName }} ({{ event.eventStartDate }})
        </li>
    </ul> -->
</div>