import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { PublicEventService } from 'src/services/publicEvent.service';
import { PublicEventVm, Season } from '../web-api-client';

@Component({
  selector: 'app-event-list-reactive',
  templateUrl: './event-list-reactive.component.html',
  styleUrl: './event-list-reactive.component.css'
})
export class EventListReactiveComponent implements OnInit {
  formGroup: FormGroup; // Reactive form group
  public events: PublicEventVm | undefined; 
  seasonOptions = [
    { label: 'Spring', value: Season.Spring },
    { label: 'Fall', value: Season.Fall }
  ];

  constructor(private fb: FormBuilder, private publicEventService: PublicEventService){  
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      eventsArray: this.fb.array([])
    });
    this.initEvents();
  }

  initEvents(){
    this.publicEventService.getAllPublicEvents().subscribe({
      next: (data) => {
        this.events = data;
        this.setFormControls();
      },
      error: (err) => {
        console.error('Error fetching public events:', err); // Handle error case
      }
    });
  }

  // Convert your events array into form controls
setFormControls() {
  const eventArray = this.formGroup.get('eventsArray') as FormArray;
  this.events.publicEvents.forEach((event, index) => {
    eventArray.push(this.fb.group({
      year: [event.year, Validators.required],
      season: [event.season, Validators.required],
      eventName: [event.eventName, Validators.required],
      description: [event.htmlEventDescription],
      startDate: [event.eventStartDate, Validators.required],
      endDate: [event.eventEndDate, Validators.required]
    }));
  });
}

get eventsArray():FormArray {
  return this.formGroup.controls["eventsArray"] as FormArray;
}

saveChanges(){
  
}

}
