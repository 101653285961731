import { Component, OnInit } from '@angular/core';
import { PublicEventService } from 'src/services/publicEvent.service';
import { PublicEvent, PublicEventDto, PublicEventVm, Season } from '../web-api-client';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrl: './event-list.component.css'
})
export class ManageEventsComponent implements OnInit {
  public events: PublicEventVm | undefined; 
  seasonOptions = [
    { label: 'Spring', value: Season.Spring },
    { label: 'Fall', value: Season.Fall }
  ];
  formGroup: FormGroup; // Reactive form group

  //#region NewUI
  selectedEvent:PublicEventDto;
  selectedEvents: PublicEventDto[]
  submitted: boolean;
  eventDialog: boolean;
  checked:boolean=true;

  //#endregion
  
  constructor(private fb: FormBuilder, 
                private publicEventService: PublicEventService,  
                private messageService: MessageService, 
                private confirmationService: ConfirmationService){}
  
  
  ngOnInit(): void {
    this.formGroup = this.fb.group({
      eventsArray: this.fb.array([])
    });    

    this.initEvents();
    
  }

  initEvents(){
    this.publicEventService.getAllPublicEvents().subscribe({
      next: (data) => {
        this.events = data; // Assign the data to the events variable
        console.log(this.events); // Optionally log the events to the console
        this.setFormControls();
      },
      error: (err) => {
        console.error('Error fetching public events:', err); // Handle error case
      }
    });
  }

  // Convert your events array into form controls
  setFormControls() {
    const eventArray = this.formGroup.get('eventsArray') as FormArray;
    this.events.publicEvents.forEach((event, index) => {
      eventArray.push(this.fb.group({
        year: [event.year, Validators.required],
        season: [event.season, Validators.required],
        eventName: [event.eventName, Validators.required],
        description: [event.htmlEventDescription],
        startDate: [event.eventStartDate, Validators.required],
        endDate: [event.eventEndDate, Validators.required]
      }));
    });
  }

  get eventsArray():FormArray {
    return this.formGroup.controls["eventsArray"] as FormArray;
  }

  saveChanges(){
    
  }

  //#region NewUI
  deleteSelectedEvents() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected events?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            // this.products = this.products.filter(val => !this.selectedEvents.includes(val));
            // this.selectedProducts = null;
            this.publicEventService.deletePublicEvents(this.selectedEvents).subscribe({
              next: (response: boolean) => {
                  // Handle the success case
                  this.messageService.add({severity:'success', summary: 'Successful', detail: 'Events Deleted', life: 3000});
                  this.eventDialog = false;
                  this.initEvents();
              },
              error: (error) => {
                  // Handle the error case
                  this.messageService.add({
                      severity: 'error',
                      summary: 'Error',
                      detail: 'Failed to create product: ' + error.message,
                      life: 3000
                  });
              }
          });
            
        }
    });
}

  openNew(){
    this.selectedEvent = new PublicEventDto();
    this.submitted = false;
    this.eventDialog = true;
  
  }

  editEvent(event: PublicEventDto) {    
    this.selectedEvent = event;
    this.eventDialog = true;
}

deleteEvent(event: PublicEventDto) {
}

hideDialog() {
  this.eventDialog = false;
  this.submitted = false;
}
saveEvent() {
  this.submitted = true;

  if (this.selectedEvent.eventName.trim()) {
      if (this.selectedEvent.publicEventId) {
        
          // this.products[this.findIndexById(this.product.id)] = this.product;                
          this.publicEventService.updatePublicEvent(this.selectedEvent).subscribe({
            next: (response: PublicEvent) => {
                // Handle the success case
                this.messageService.add({
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Event Updated',
                    life: 3000
                });
                this.eventDialog = false;
                this.initEvents();
            },
            error: (error) => {
                // Handle the error case
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to create product: ' + error.message,
                    life: 3000
                });
            }
        });
      }
      else {
          // this.product.id = this.createId();
          // this.product.image = 'product-placeholder.svg';
          // this.products.push(this.product);
          this.publicEventService.createPublicEvent(this.selectedEvent).subscribe({
            next: (response: PublicEvent) => {
                // Handle the success case
                this.messageService.add({
                    severity: 'success',
                    summary: 'Successful',
                    detail: 'Event Created',
                    life: 3000
                });
                this.eventDialog = false;
                this.initEvents();
            },
            error: (error) => {
                // Handle the error case
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to create product: ' + error.message,
                    life: 3000
                });
            }
        });
      }

      // this.products = [...this.products];
      
      // this.product = {};
  }
}

  //#endregion

}
