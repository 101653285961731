<p-toast></p-toast>
<div class="eventListContainer">
    <div class="button-container">
        <button pButton type="button" label="Add New Event" icon="pi pi-plus"></button>
        <button pButton type="button" label="Save Changes" icon="pi pi-save" [disabled]="!formGroup.dirty"
            (click)="saveChanges()"></button>
    </div>
    <form [formGroup]="formGroup">
        <p-table [value]="eventsArray.controls" 
        [resizableColumns]="true" 
        styleClass="p-datatable-gridlines"
        class="custom-gridlines" 
        [paginator]="true" 
        [rows]="5" 
        [responsive]="true">
            <ng-template pTemplate="header">
                <tr>
                    <th pResizableColumn>Year</th>
                    <th pResizableColumn>Season</th>
                    <th pResizableColumn>Title</th>
                    <th pResizableColumn>Description</th>
                    <th pResizableColumn>Start Date</th>
                    <th pResizableColumn>End Date</th>
                    <th pResizableColumn>Actions</th>
                </tr>
            </ng-template>


            <ng-template pTemplate="body"  let-rowData let-rowIndex="rowIndex">

                <ng-container formArrayName="eventsArray">

                    <tr [formGroup]="rowData">
                        <td pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input type="text" pInputText formControlName="year" />
                                </ng-template>
                                <ng-template pTemplate="output">{{ formGroup.get('eventsArray').value[rowIndex]?.year
                                    }}</ng-template>
                            </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                            <p-cellEditor>
                                <!-- Input Template for Editing -->
                                <ng-template pTemplate="input">
                                    <!-- Dropdown for Season -->
                                    <p-dropdown [options]="seasonOptions" formControlName="season"
                                        optionLabel="label" optionValue="value" placeholder="Select Season"
                                        [styleClass]="'custom-dropdown'" appendTo="body">
                                    </p-dropdown>
                                </ng-template>

                                <!-- Output Template for Display -->
                                <ng-template pTemplate="output">{{ formGroup.get('eventsArray').value[rowIndex]?.season === 0
                                    ?
                                    'Spring'
                                    : 'Fall' }}</ng-template>
                            </p-cellEditor>
                        </td>

                        <td pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input type="text" pInputText formControlName="eventName" />
                                </ng-template>
                                <ng-template pTemplate="output">{{ formGroup.get('eventsArray').value[rowIndex]?.eventName
                                    }}</ng-template>
                            </p-cellEditor>
                        </td>

                        <td pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input type="text" pInputText formControlName="description" />
                                </ng-template>
                                <ng-template pTemplate="output">{{ formGroup.get('eventsArray').value[rowIndex]?.description
                                    }}</ng-template>
                            </p-cellEditor>
                        </td>

                        <td pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input type="date" pInputText formControlName="startDate" />
                                </ng-template>
                                <ng-template pTemplate="output">{{ formGroup.get('eventsArray').value[rowIndex]?.startDate |
                                    date:'dd-MMM-yyyy' }}</ng-template>
                            </p-cellEditor>
                        </td>

                        <td pEditableColumn>
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <input type="date" pInputText formControlName="endDate" />
                                </ng-template>
                                <ng-template pTemplate="output">{{ formGroup.get('eventsArray').value[rowIndex]?.endDate |
                                    date:'dd-MMM-yyyy' }}</ng-template>
                            </p-cellEditor>
                        </td>
                        <!-- <td>
                    <p-button class="smaller-button" label="Edit" icon="pi pi-pencil" (click)="editEvent(event)" />
                    <p-button class="smaller-button" label="Delete" icon="pi pi-trash" (click)="deleteEvent(event)" />
                </td> -->
                    </tr>

                </ng-container>

            </ng-template>

        </p-table>
    </form>
</div>