import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';


import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { TodoComponent } from './todo/todo.component';
import { AuthInterceptor } from 'src/api-authorization/authorize.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from 'src/RouteGuards/auth.guard';
import { MainLayoutComponent } from './app-main-layout/app-main-layout.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { UpcomingEventsComponent } from './upcoming-events/upcoming-events.component';
import { AnnouncementsComponent } from './announcements/announcements.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppRoutingModule } from './app-routing.module';
import { EventListReactiveComponent } from './event-list-reactive/event-list-reactive.component';
import { ConfigService } from 'src/services/config-service.service';

export function loadConfig(configService: ConfigService) {
    return () => configService.loadConfig().toPromise().then((config) => {
      configService.setConfig(config);
    });
  }


@NgModule({ declarations: [
        AppComponent,
        NavMenuComponent,
        HomeComponent,
        CounterComponent,
        FetchDataComponent,
        TodoComponent,
        LoginComponent,
        MainLayoutComponent,
        WelcomeComponent,
        UpcomingEventsComponent,
        AnnouncementsComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserAnimationsModule,
        AppRoutingModule,
        RouterModule,
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        FormsModule
        // Include ReactiveFormsModule for reactive forms 
    ], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        MessageService,
        ConfirmationService,
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: APP_INITIALIZER,
            useFactory: loadConfig,
            deps: [ConfigService],
            multi: true,
          }
    ] })
export class AppModule { }
